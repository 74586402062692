<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"/>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-tabs
                        v-model="salesDataOverviewCurrentTab"
                        color="success"
                        background-color="grey lighten-2"
                        slider-color="appic-green"
                    >
                        <v-tab key="salesBudgets" @click="changeExportParams('salesBudgets')">{{ $t('message.salesBudgets') }}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="salesDataOverviewCurrentTab">
                        <v-tab-item key="agents-list">
                            <v-card flat>
                                <SalesBudgetsListing
                                    :add-dialog="openAddSalesBudgetDialog"
                                    :key="salesBudgetsListKey"
                                    @add-dialog-closed="addSalesBudgetDialogClosed"
                                    @reload="reloadSalesBudgetsList"
                                />
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";

const SalesBudgetsListing = () => import("Components/Appic/SalesBudgetsListing");

export default {
    name: "OperationsDataOverview",
    components: { SalesBudgetsListing },
    title: '',
    data() {
        return {
            loader: false,
            active: null,
            colsToDelete: {
                start_col: 10,
                ncols: 1
            },
            fileName: 'salesBudgets',
            hasAddFunction: true,
            document: 'salesBudgets',
            tableId: 'salesBudgetsTable',
            openAddSalesBudgetDialog: false,
            salesBudgetsListKey: 0
        }
    },
    computed: {
        ...mapFields('runtime',{
            salesDataOverviewCurrentTab: 'salesDataOverviewCurrentTab'
        })
    },
    methods: {
        addSalesBudgetDialogClosed () {
            this.openAddSalesBudgetDialog = false
        },
        changeExportParams(itemType){
            switch (itemType) {
                case 'salesBudgets':
                    this.colsToDelete = {start_col: 0, ncols: 1}
                    this.fileName = 'salesBudgets'
                    this.hasAddFunction = true
                    this.document = 'salesBudgets'
                    this.tableId = 'salesBudgetsTable'
                    break
            }
        },
        newItem (itemType) {
            switch(itemType){
                case 'salesBudgets':
                    this.openAddSalesBudgetDialog = true
                    break
            }
        },
        reloadSalesBudgetsList () {
            this.salesBudgetsListKey = Math.floor(Math.random() * 100)
        }
    },
    // mounted() {
    //     switch(this.salesDataOverviewCurrentTab){
    //         case 0:
    //             this.changeExportParams('agents')
    //             break
    //         case 1:
    //             this.changeExportParams('ports')
    //             break
    //         case 2:
    //             this.changeExportParams('countries')
    //             break
    //         case 3:
    //             this.changeExportParams('sales')
    //             break
    //         case 5:
    //             this.changeExportParams('sidocaddresses')
    //             break
    //     }
    // }
}
</script>

<style>

</style>